import { useCallback } from 'react';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const useRecaptcha = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const getRecaptchaToken = useCallback(async (action: string) => {
    if (!executeRecaptcha) {
      // eslint-disable-next-line no-console
      console.error('Execute recaptcha not yet available');
      return null;
    }

    const token = await executeRecaptcha(action);

    return token;
  }, [executeRecaptcha]);

  return {
    getRecaptchaToken,
  } as const;
};

export default useRecaptcha;

import * as React from "react";
import { graphql } from "gatsby";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import { useMutation, useQuery } from "react-apollo";
import Meta from "../../../components/meta";
import gql from "graphql-tag";
import Layout from "../../../components/layout/Layout";
import Content from "../../../components/layout/Content/Content";
import Headline from "../../../components/texts/Headline";
import Description from "../../../components/texts/Description";
import Button from "../../../components/buttons/Button";
import BackgroundMerlin from "../../../components/pages/merlin/BackgroundMerlin";
import Form from "../../../components/form/Form";

import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  RedditShareButton,
  RedditIcon
} from 'react-share'
import useRecaptcha from "../../../hooks/useRecaptcha";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import constants from "../../../constants";

const MERLIN_DASHBOARD = gql`
  query getMerlinDashboard($dashboardSlug: String!) {
    merlin {
      dashboard(dashboardSlug: $dashboardSlug) {
        shareUrl
        queueNumber
      }
    }
  }
`;

const MERLIN_SHARE_BY_EMAIL = gql`
  mutation merlinShareByEmail($dashboardSlug: String!, $emails: [String!]!, $message: String, $recaptchaToken: String!) {
    merlinShareByEmail(dashboardSlug: $dashboardSlug, emails: $emails, message: $message, recaptchaToken: $recaptchaToken) {
      empty
    }
  }
`;

const Container = Styled.div`
  padding: 6rem 0 3rem 0;
  overflow: hidden; // fix for columns overflow

  ${Media.greaterThan("medium")`
    padding: 15rem 0 5rem 0
  `}
`;

const ContentExtended = Styled(Content)`
  width: 107rem;
  max-width: calc(100% - 4rem);
  z-index: 1;
  position: relative;
`;

const ContentIntermediate = Styled(Content)`
  height: 100%;
`;

const DescriptionExtended = Styled(Description)`
  width: 100%;
  margin: 0 auto 3rem auto;
  font-size: 2.6rem;
  text-align: center;
  font-weight: 500;

  ${Media.greaterThan("700px")`
    width: 80%;
    font-size: 3rem;
    margin-bottom: 3rem;
    letter-spacing: -1px;
  `}
`;

const FormLabel = Styled.label`
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
  display: block;
`;

const Queue = Styled(DescriptionExtended)`
  width: 100%;
  padding: 3rem;
  background-color: #FFFFFF;
  border-radius: 32px;
  color: #030303;
  text-align: center;

  ${Media.greaterThan("medium")`
    width: 560px;
  `}
`;

const ExtendedHeadline = Styled(Headline)`
  text-align: center;
  font-size: 3.2rem;
  line-height: 3.2rem;
  max-width: 100%;
  margin: 0 0 4rem 0;
  font-weight: 500;
  letter-spacing: -2px;
  
  ${Media.greaterThan("medium")`
    font-size: 6rem;
    line-height: 6rem;
    letter-spacing: -3px;
    margin: 0 auto 4rem auto;
  `}
`;

const UniqueLinkContainer = Styled.div`
  position: relative;
`;

const UniqueLinkCard = Styled.span`
  background: #FFFFFF;
  font-weight: 500;
  color: #545454;
  border-radius: 16px;
  padding: 0 2.5rem;
  width: 100%;
  margin-bottom: 1rem;
  height: 6.2rem;
  font-size: 1.5rem;
  line-height: 2rem;
  display: inline-flex;
  align-items: center;

  ${Media.greaterThan("medium")`
    font-size: 2rem;
    position: absolute;
    left: 0;
    margin-bottom: 0;
  `}
`;

const ButtonExtended = Styled(Button)`
  border: none;
  color: #FFFFFF;
  background: linear-gradient(to right, #0094FF, #7000FF);
  border-radius: 16px;
  width: 100%;
  height: 6.2rem;

  &:active {
    background: radial-gradient(#7000FF, #0094FF);
  }
`;

const ButtonExtendedRight = Styled(ButtonExtended)`
  ${Media.greaterThan("medium")`
    position: absolute;
    right: 0;
    width: auto;
  `}
`;

const Section = Styled.div`
  width: 100%;
  margin: auto;
  margin-top: 6rem;

  ${Media.greaterThan("medium")`
    width: 560px;
  `}
`;

const SectionSocial = Styled(Section)`
  margin-top: 6rem;

  ${Media.greaterThan("medium")`
    margin-top: 12rem;
  `}
`;

const SectionHeader = Styled(DescriptionExtended)`
  font-weight: 500;
  margin: 0 auto 2rem auto;
`;

const FormExtended = Styled(Form)`
`;

const TextAreaExtended = Styled.textarea`
  background: #FFFFFF;
  font-weight: 500;
  color: #545454;
  border-radius: 16px;
  padding: 2rem;
  width: 100%;
  border: none;
  height: 160px;
  font-size: 2rem;
  line-height: 1.2;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  margin: 1rem auto 2rem auto;

  &::placeholder {
    border: none;
  }

  &::selection {
    border: none;
    background-color: #DDDDDD;
  }
  
  &:focus {
    border: none;
  }
`;

const SocialMediaContainer = Styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${Media.greaterThan("medium")`
    flex-direction: row;
  `}
`;

const FacebookShareButtonExt = Styled(FacebookShareButton)`
  background-color: #3b5998 !important;
  height: 40px !important;
  border-radius: 16px !important;
`;

const LinkedinShareButtonExt = Styled(LinkedinShareButton)`
  background-color: #007fb1 !important;
  height: 40px !important;
  border-radius: 16px !important;
  margin-top 1rem !important;

  ${Media.greaterThan("medium")`
    margin-top 0 !important;
  `}
`;

const TwitterShareButtonExt = Styled(TwitterShareButton)`
  background-color: #00aced !important;
  height: 40px !important;
  border-radius: 16px !important;
  margin-top 1rem !important;

  ${Media.greaterThan("medium")`
    margin-top 0 !important;
  `}
`;

const RedditShareButtonExt = Styled(RedditShareButton)`
  background-color: #ff4500 !important;
  height: 40px !important;
  border-radius: 16px !important;
  margin-top 1rem !important;

  ${Media.greaterThan("medium")`
    margin-top 0 !important;
  `}
`;

const SocialFlex = Styled.div`
  font-size: 1.9rem;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 0.6rem;
  padding-right: 1.6rem;
  justify-content: center;

  ${Media.greaterThan("medium")`
    justify-content: space-between;
  `}
`;

const getSlug = () => {
  try {
    const url = new URL(window.location.href);
    const params = url.searchParams;
    const slug = params.get("slug");
    return slug;
  } catch (error) {
    console.error("Failed to retrieve slug.");
  }
};

const ShareByLink = ({ shareUrl }) => {
  const [isCopied, setIsCopied] = React.useState(false)
  
  const handleCopyClick = () => {
    navigator.clipboard.writeText(shareUrl)
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 2500)
  }

  return (
    <Section>
      <SectionHeader>Share your unique link</SectionHeader>
      
      <UniqueLinkContainer>
        <UniqueLinkCard>{shareUrl}</UniqueLinkCard>
        <ButtonExtendedRight
                solid
                onClick={handleCopyClick}
                type="submit"
              >
              {isCopied ? "Copied!" : "Copy"}
        </ButtonExtendedRight>
      </UniqueLinkContainer>
    </Section>
  )
};

const ShareBySoMe = ({ shareUrl }) => {

  return (
    <SectionSocial>
      <SectionHeader>Share on social media</SectionHeader>
      
      <SocialMediaContainer>
        <FacebookShareButtonExt url={shareUrl} >
          <SocialFlex>
            <FacebookIcon  size={40} round={true}/>
            Facebook
          </SocialFlex>
        </FacebookShareButtonExt>

        <TwitterShareButtonExt
          url={shareUrl}
          title={"I just signed up to try Autodesigner, a new generative AI by @uizard for UI/UX design. Looks incredible!"}
          hashtags={["GenerativeAI", "Autodesigner", "UIdesign", "UXdesign"]}>
          <SocialFlex>
            <TwitterIcon  size={40} round={true} />
            <span>Twitter</span>
          </SocialFlex>
        </TwitterShareButtonExt>

        <LinkedinShareButtonExt url={shareUrl} >
          <SocialFlex>
            <LinkedinIcon  size={40} round={true}/>
            LinkedIn
          </SocialFlex>
        </LinkedinShareButtonExt>

        <RedditShareButtonExt
          url={shareUrl}
          title={"I just signed up to try Autodesigner, a new generative AI by @uizard for UI/UX design. Looks incredible!"} >
          <SocialFlex>
            <RedditIcon  size={40} round={true} />
            Reddit
          </SocialFlex>
        </RedditShareButtonExt>
      </SocialMediaContainer>
    </SectionSocial>
  )
};

const ShareByEmail = ({ dashboardSlug }) => {
  const { getRecaptchaToken } = useRecaptcha();

  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [state, setState] = React.useState<'idle' | 'submitting' | 'success' | 'error'>('idle');
  const [validation, setValidation] = React.useState<string>();
  const [emails, setEmails] = React.useState<string>('');
  const [message, setMessage] = React.useState<string>('');

  const [merlinShareByEmail] = useMutation(MERLIN_SHARE_BY_EMAIL);

  const onSubmit = React.useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    try {
      if (!`${emails}`.trim()) {
        return setValidation('Please enter at least one email address');
      }

      setState('submitting');
      setErrorMessage(undefined);
      setValidation(undefined);

      const emailList = emails.split(/[ ,]+/).map(email => email.trim());

      const recaptchaToken = await getRecaptchaToken('merlin_invite');

      await merlinShareByEmail({
        variables: {
          dashboardSlug,
          emails: emailList,
          message,
          recaptchaToken,
        },
      });

      setState('success');
    } catch (error) {
      console.error(error);
      setState('error');
      try {
        const errors = JSON.parse(error.graphQLErrors?.[0].message || '[]');
        setErrorMessage(errors[0].message);
      // eslint-disable-next-line no-empty
      } catch (e) {}
    }
    
  }, [emails, message, dashboardSlug, merlinShareByEmail]);

  const onReset = React.useCallback(() => {
    setState('idle');
    setEmails('');
    setMessage('');
  }, []);

  return (
    <Section>
      <SectionHeader>Invite by email</SectionHeader>

      {(state === 'idle' || state === 'submitting') && (
        <FormExtended onSubmit={onSubmit}>
          <div>
            <FormLabel>Enter the emails of the people you want to invite</FormLabel>
            <TextAreaExtended
              value={emails}
              onChange={(event) => setEmails(event.target.value)}
              placeholder="Enter email addresses separated by commas: elon@spacex.com, satya@microsoft.com, ..."
              disabled={state === 'submitting'}
            />
          </div>

          <div>
            <FormLabel>Add a custom message (optional)</FormLabel>
            <TextAreaExtended
              value={message}
              onChange={(event) => setMessage(event.target.value)}
              placeholder="Hey check this out, Uizard Autodesigner is wicked!"
              disabled={state === 'submitting'}
            />
          </div>

          <ButtonExtended
            solid
            type="submit"
            disabled={state === 'submitting'}
          >
            {state === 'submitting' ? 'Sending Invites...' : 'Send Invites'}
          </ButtonExtended>

          {validation}
        </FormExtended>
      )}

      {state === 'success' && (
        <div>
          <FormLabel>Invitations sent successfully! 👍</FormLabel>
          <ButtonExtended solid type="button" onClick={onReset}>
            Invite more people
          </ButtonExtended>
        </div>
      )}

      {state === 'error' && (
        <div>
          <FormLabel>{errorMessage ?? 'Could not invite. Please try again'}</FormLabel>
          <ButtonExtended solid type="button" onClick={onReset}>
            Try again
          </ButtonExtended>
        </div>
      )}
      
    </Section>
  )
};

const MerlinDashboardPage = () => {
  const dashboardSlug = getSlug();
  
  const { data, loading, error } = useQuery(MERLIN_DASHBOARD, {
    variables: {
      dashboardSlug,
    },
  });
  
  const { queueNumber, shareUrl } = React.useMemo(() => (
    data?.merlin.dashboard ?? { queueNumber: 0, shareUrl: '' }
  ), [data]);

  if (loading) {
    return (
      <ContentIntermediate>
        <DescriptionExtended>
          Loading...
        </DescriptionExtended>
      </ContentIntermediate>
    )
  }

  if (error || !dashboardSlug) {
    return (
      <ContentIntermediate>
        <DescriptionExtended>
          Ooops, error loading this page 😱
          <br/>
          Please refresh this page to try again.
        </DescriptionExtended>
      </ContentIntermediate>
    )
  }

  return (
    <Content>
      <ExtendedHeadline as="h1">Thanks for signing up to be the first to experience the future of design!</ExtendedHeadline>
      <Queue>You are number <strong>#{queueNumber}</strong> on the waitlist</Queue>

      <DescriptionExtended>
        Share your excitement to get up the waitlist! 💙
        <br/>
        Every time someone signs up to Autodesigner with your unique link, you'll move up the list by <strong>100</strong> spots.
      </DescriptionExtended>

      <ShareByLink shareUrl={shareUrl} />
      <ShareBySoMe shareUrl={shareUrl}/>
      <ShareByEmail dashboardSlug={dashboardSlug} />
    </Content>
  );
};

const MerlinDashboardWrapper = ({ data }) => (
  <GoogleReCaptchaProvider reCaptchaKey={constants.RECAPTCHA_SITE_KEY}>
    <Layout type="dark" news="hide">
      <Meta
        title="Uizard Autodesigner | Your Number In The Waiting List | Uizard"
        description="You signed up to the AI design revolution."
        url="/autodesigner/dashboard/"
        metaimage="https://uizard.io/opengraph-autodesigner-2023-02-17.png"
        noindex={true}
      />
      
      <BackgroundMerlin
        backgroundGridWeb={data.backgroundGridWeb.childImageSharp}
        backgroundGridMobile={data.backgroundGridMobile.childImageSharp}
        alpha={0.5}
      />

      <Container>
        <ContentExtended>
          <MerlinDashboardPage/>
        </ContentExtended>
      </Container>

    </Layout>
  </GoogleReCaptchaProvider>
);

export default MerlinDashboardWrapper;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    backgroundGridWeb: file(relativePath: { eq: "merlin/grid-web.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    backgroundGridMobile: file(relativePath: { eq: "merlin/grid-mobile.png" }) {
      childImageSharp {
        fluid(maxWidth: 375, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
